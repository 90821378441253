document.addEventListener('DOMContentLoaded', function () {
  var swiper = new Swiper('.js-swiper', {
    loop: true,
    loopAdditionalSlides: 1,
    loopedSlides:1,
    slidesPerView: 'auto',
    centeredSlides: true,
    speed: 7000,
    autoplay: {
      delay: 0,
    },
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
    },
  });
})